import React from 'react'
import { connect } from 'react-redux'
import { getPromotions } from '../../reducers'
import { APIpath, fetchActivePromotions } from '../../actions'
import { Link } from 'react-router-dom'
import { Card } from 'antd'
import bannerpromocion from '../../multimedia/bannerpromocion.png'
import Layout from '../../layout'
import styles from '../../styles'

class Promotion extends React.Component {

    componentDidMount() {
        this.props.fetchActivePromotions()
    }

    render() {
        const { promotions } = this.props
        return <Layout type={"frontpage"}>
            <Card
                style={styles.generalCard}
                bordered={false}
                cover={<img src={bannerpromocion} alt={"Fondo"} />}
                actions={[<Link to={"/"} key={1}>Regresar</Link>]}
            >
                {promotions.map((promotion) => {
                    return (
                        <Card
                            cover={<img src={`${APIpath}/promotion/image/${promotion.id}`} alt={promotion.description} />}
                            key={promotion.id}
                        >
                        </Card>
                    )
                })
                }
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    promotions: getPromotions(state)
})

const mapDispatchToProps = { fetchActivePromotions }

export default connect(mapStateToProps, mapDispatchToProps)(Promotion)