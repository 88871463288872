import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
        backgroundColor: '#FFF'
    },
    section: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '3px'
    },
    section2: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    section3: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '3px',
        marginTop: '100px'
    },
    section4: {
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: '5px'
    },
    title: {
        fontSize: 8,
        textAlign: 'center',
        fontWeight: 'black',
        textTransform: 'uppercase'
    },
    text: {
        fontSize: 7,
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textNormalCenter: {
        fontSize: 7,
        textAlign: 'center',
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textNormalRight: {
        fontSize: 7,
        textAlign: 'right',
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textRed: {
        fontSize: 7,
        textAlign: 'center',
        color: 'red',
        textTransform: 'uppercase'
    },
    textMini: {
        fontSize: 4,
        textAlign: 'left',
        textTransform: 'uppercase'
    },
    boxCompany: {
        width: '40%',
        justifyContent: 'center'
    },
    boxLogo: {
        width: '25%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxInfo: {
        border: 2,
        width: '20%',
        justifyContent: 'center'
    },
    boxClient: {
        border: 1,
        width: '95%',
        margin: 'auto',
        flexDirection: 'row'
    },
    boxClient2: {
        borderLeft: 1,
        borderRight: 1,
        width: '95%',
        margin: 'auto',
        flexDirection: 'row'
    },
    boxClient3: {
        border: 0,
        width: '95%',
        margin: 'auto',
        flexDirection: 'row'
    },
    boxClient4: {
        border: 1,
        borderBottom: 0,
        width: '95%',
        margin: 'auto',
        flexDirection: 'row'
    },
    textMark: {
        color: '#989898',
        fontSize: 24,
        fontWeight: 'bold',
    },
    markBox: {
        top: 275,
        left: 100,
        position: 'absolute',
        zIndex: '1000',
        transform: 'rotate(-25deg)'
    }
})

const MyDocument = ({ voucher }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section3}>
                <View style={styles.boxLogo}>
                    <Text style={styles.textMini}></Text>
                </View>
                <View style={styles.boxCompany}>
                    <Text style={styles.title}>{voucher.cEmpresa}</Text>
                    <Text style={styles.title}>AGENCIA: {voucher.NOMBREAGENCIA}</Text>
                    <Text style={styles.textNormalCenter}>{voucher.DIRECCIONAGE}</Text>
                    <Text style={styles.textNormalCenter}> RUC DE EMPRESA: {voucher.cRUCEmpresa}</Text>
                    <Text style={styles.textNormalCenter}>{voucher.AutorizacionNIC}</Text>
                </View>
                <View style={styles.boxInfo}>
                    <Text style={styles.textRed}>RECIBO OFICIAL DE CAJA</Text>
                    <Text style={styles.textRed}>No. {voucher.NUMERODOCUMENTO}</Text>
                </View>
            </View>
            <View style={styles.section}>
                <View style={styles.boxClient} >
                    <View style={{ width: '40%', justifyContent: 'flex-start', padding: '5px' }}>
                        <Text style={styles.text}>CONTRATO: {voucher.NUMEROCONTRATO}</Text>
                        <Text style={styles.text}>CLIENTE: {voucher.NOMBRE}</Text>
                        <Text style={styles.text}>DIRECCIÓN: {voucher.DIRECCION} </Text>
                    </View>
                    <View style={{ width: '30%', justifyContent: 'flex-start', padding: '5px' }}>
                        <Text style={styles.text}>CEDULA: {voucher.ID}</Text>
                        <Text style={styles.text}>TIPO OPERACIÓN: {voucher.DescTipoOperacion} </Text>
                        <Text style={styles.text}>TELÉFONO: </Text>
                    </View>
                    <View style={{ width: '30%', justifyContent: 'flex-start', padding: '5px' }}>
                        <Text style={styles.text}>Fecha: {new Date(voucher.FECHACREO).toLocaleDateString('es')}</Text>
                        <Text style={styles.text}></Text>
                        <Text style={styles.text}>Condiciones: {voucher.TIPOOPERPAGO}</Text>
                    </View>

                </View>
            </View>

            <View style={styles.section2}>
                <View style={styles.boxClient4}>
                    <View style={{ borderRight: 1, width: '75%', paddingLeft: '10%' }}>
                        <Text style={styles.text}>Intereses</Text>
                        <Text style={styles.text}>Admin. Custodia, Seguro y Legal:</Text>
                        <Text style={styles.text}>Mora:</Text>
                        <Text style={styles.text}>Intereses No Pagado:</Text>
                        <Text style={styles.text}>Intereses Atrasado:</Text>
                        <Text style={styles.text}>Descuento dias de gracia:</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '5%' }}>
                        <Text style={styles.textNormalCenter}> </Text>
                        <Text style={styles.textNormalCenter}>(-)</Text>
                        <Text style={styles.textNormalCenter}>(+)</Text>
                        <Text style={styles.textNormalCenter}>(+)</Text>
                        <Text style={styles.textNormalCenter}>(-)</Text>
                        <Text style={styles.textNormalCenter}>(-)</Text>
                    </View>
                    <View style={{ width: '20%', paddingLeft: '5%', paddingRight: '5%' }}>
                        <Text style={styles.textNormalRight}>C${voucher.INTERES.toFixed(2)}</Text>
                        <Text style={styles.textNormalRight}>C${(voucher.MONTOLEGAL + voucher.MONTOGASADMIN + voucher.MONTOCUSTODIA + voucher.MONTOSEGURO).toFixed(2)}</Text>
                        <Text style={styles.textNormalRight}>C${voucher.MORA.toFixed(2)}</Text>
                        <Text style={styles.textNormalRight}>C${voucher.INTERESESNOPAGADOS.toFixed(2)}</Text>
                        <Text style={styles.textNormalRight}>C${voucher.INTERESESATRASADOS.toFixed(2)}</Text>
                        <Text style={styles.textNormalRight}>C${voucher.DESCUENTO.toFixed(2)}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.section2}>
                <View style={styles.boxClient2}>
                    <View style={{ width: '55%' }}>
                        <Text style={styles.text}> </Text>
                    </View>
                    <View style={{ border: 1, width: '20%' }}>
                        <Text style={styles.textNormalCenter}>SUBTOTAL</Text>
                    </View>
                    <View style={{ width: '25%', paddingRight: '5%', borderTop: 1 }}>
                        <Text style={styles.textNormalRight}>C${voucher.SUBTOTAL1.toFixed(2)}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.section2}>
                <View style={styles.boxClient4}>
                    <View style={{ width: '30%', paddingLeft: '5%', borderBottom: 1 }}>
                        <Text style={styles.text}>Saldo disponible: C${voucher.SaldoDisponible.toFixed(2)} </Text>
                    </View>
                    <View style={{ width: '25%', borderBottom: 1 }}>
                        <Text style={styles.text}>Saldo Saldo Prestamo: C${voucher.SALDOPRESTAMO.toFixed(2)}</Text>
                    </View>
                    <View style={{ borderLeft: 1, borderRight: 1, width: '20%' }}>
                        <Text style={styles.textNormalCenter}> </Text>
                    </View>
                    <View style={{ width: '25%', paddingRight: '5%' }}>
                        <Text style={styles.textNormalRight}> </Text>
                    </View>
                </View>
            </View>
            <View style={styles.section2}>
                <View style={styles.boxClient2}>
                    <View style={{ width: '30%' }}>
                        <Text style={styles.text}></Text>
                    </View>
                    <View style={{ width: '25%', }}>
                        <Text style={styles.text}>{voucher.EtiquetaAmortiza} C${voucher.MONTOTIPOPAG.toFixed(2)}</Text>
                    </View>
                    <View style={{ borderLeft: 1, borderRight: 1, width: '20%' }}>
                        <Text style={styles.textNormalCenter}> </Text>
                    </View>
                    <View style={{ width: '25%', paddingRight: '5%' }}>
                        <Text style={styles.textNormalRight}> </Text>
                    </View>
                </View>
            </View>

            <View style={styles.section2}>
                <View style={styles.boxClient2}>
                    <View style={{ width: '20%', paddingLeft: '3%', borderRight: 1, borderBottom: 1 }}>
                        <Text style={styles.text}>FECHA DE VENCIMIENTO</Text>
                    </View>
                    <View style={{ width: '17%', borderRight: 1, borderBottom: 1 }}>
                        <Text style={styles.text}>PAGO AL VENCIMIENTO</Text>
                    </View>
                    <View style={{ width: '18%', borderBottom: 1 }}>
                        <Text style={styles.text}>FECHA MAXIMA DE PAGO</Text>
                    </View>
                    <View style={{ borderLeft: 1, borderRight: 1, width: '20%', borderBottom: 1 }}>
                        <Text style={styles.textNormalCenter}> </Text>
                    </View>
                    <View style={{ width: '25%', paddingRight: '5%' }}>
                        <Text style={styles.textNormalCenter}> </Text>
                    </View>
                </View>
            </View>

            <View style={styles.section2}>
                <View style={styles.boxClient2}>
                    <View style={{ width: '20%', paddingLeft: '3%', borderRight: 1 }}>
                        <Text style={styles.text}>{new Date(voucher.FECHAVENCE).toLocaleDateString('es')} </Text>
                    </View>
                    <View style={{ width: '17%', borderRight: 1 }}>
                        <Text style={styles.text}>C${voucher.PAGOPROYECTADO.toFixed(2)}</Text>
                    </View>
                    <View style={{ width: '18%' }}>
                        <Text style={styles.text}>{voucher.FECHAMAXIMAPAGO} </Text>
                    </View>
                    <View style={{ borderLeft: 1, borderRight: 1, width: '20%' }}>
                        <Text style={styles.textNormalCenter}>TOTAL</Text>
                    </View>
                    <View style={{ width: '25%', paddingRight: '5%' }}>
                        <Text style={styles.textNormalRight}>C${voucher.TOTAL2.toFixed(2)}</Text>
                    </View>
                </View>
            </View>


            <View style={styles.section2}>
                <View style={styles.boxClient}>
                    <View style={{ borderRight: 1, width: '100%' }}>
                        <Text style={styles.text}> </Text>
                    </View>
                </View>
            </View>

            <View style={styles.section4}>
                <View style={styles.boxClient3}>
                    <View style={{ width: '70%' }}>
                        <Text style={styles.text}>Oficina Central: Residencial Bolonia. Telf: 2266-6046</Text>
                    </View>
                    <View style={{ width: '30%' }}>
                        <Text style={styles.textNormalRight}>REIMPRESION</Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);

export default MyDocument