import React from 'react'
import './App.less'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { connect } from 'react-redux'
import { fetchConfiguration, fetchAgencies, fetchEntity } from './actions'
import { getLoader } from './reducers'
import PrivateRoute from './containers/PrivateRoute'
import Error404 from './components/404'
import ErrorBoundary from './components/ErrorBoundary'
import { Spin } from 'antd'

//Rutas
//Frontpage
import Home from './pages/front/Home'
import Login from './pages/front/Login'
import Account from './pages/front/Account'
import Profile from './pages/front/Profile'
import ContractList from './pages/front/ContractList'
import InvoiceList from './pages/front/InvoiceList'
import InvoiceDetail from './pages/front/InvoiceDetail'
import AccountCash from './pages/front/AccountCash'
import Agency from './pages/front/Agency'
import AgencyMenu from './pages/front/AgencyMenu'
import AgenciesList from './pages/front/AgenciesList'
import Goodies from './pages/front/Goodies'
import PrevalueList from './pages/front/PrevalueList'
import PrevalueElectro from './pages/front/PrevalueElectro'
import PrevalueJewelry from './pages/front/PrevalueJewelry'
import Promotion from './pages/front/Promotion'
import Faq from './pages/front/Faq'


//No login
import NoLPrevalueList from './pages/front/NoLPrevalueList'
import NoLPrevalueElectro from './pages/front/NoLPrevalueElectro'
import NoLPrevalueJewelry from './pages/front/NoLPrevalueJewelry'

//Adminpage
import AdminLogin from './pages/admin/Login'
import AdminProfile from './pages/admin/Profile'
import AdminDashboard from './pages/admin/Dashboard'
import AdminAdminList from './pages/admin/AdminList'
import AdminCreateAdmin from './pages/admin/CreateAdmin'
import AdminUpdateAdmin from './pages/admin/UpdateAdmin'
import AdminAgencyList from './pages/admin/AgencyList'
import AdminAgency from './pages/admin/Agency'
import AdminUserList from './pages/admin/UserList'
import AdminCreateUser from './pages/admin/CreateUser'
import AdminUpdateUser from './pages/admin/UpdateUser'
import AdminConfiguration from './pages/admin/Configuration'
import AdminCreatePromotion from './pages/admin/CreatePromotion'
import AdminUpdatePromotion from './pages/admin/UpdatePromotion'
import AdminPromotionList from './pages/admin/PromotionList'
import AdminCreateBanner from './pages/admin/CreateBanner'
import AdminUpdateBanner from './pages/admin/UpdateBanner'
import AdminBannerList from './pages/admin/BannerList'
import AdminprevaluesElectroList from './pages/admin/PrevaluesElectroList'
import AdminPrevaluesJewelryList from './pages/admin/PrevaluesJewelryList'
import AdminPrevalueElectro from './pages/admin/PrevalueElectro'
import AdminPrevalueJewelry from './pages/admin/PrevalueJewelry'


class App extends React.Component {

  componentDidMount() {
    this.props.fetchConfiguration()
    this.props.fetchAgencies()
    this.props.fetchEntity('departamento')
  }

  render() {
    const { loader } = this.props
    return <Router>

      {loader &&
        <div style={{
          zIndex: 2000,
          background: 'rgba(0,0,0,0.4)',
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Spin size={"large"} tip={"Cargando espera"} />
        </div>
      }
      <ErrorBoundary>
        <Switch>
          <Route path={"/login"} exact component={Login} accessRole={'user'} />
          <PrivateRoute path={"/"} exact component={Home} accessRole={'user'} />
          <PrivateRoute path={"/profile"} exact component={Profile} accessRole={'user'} />
          <PrivateRoute path={"/account"} exact component={Account} accessRole={'user'} />
          <PrivateRoute path={"/account/contracts"} exact component={ContractList} accessRole={'user'} />
          <PrivateRoute path={"/account/goodies"} exact component={Goodies} accessRole={'user'} />
          <PrivateRoute path={"/account/contracts/detail/:id"} exact component={InvoiceList} accessRole={'user'} />
          <PrivateRoute path={"/account/invoice/check/:id/:age/:prod"} exact component={InvoiceDetail} accessRole={'user'} />
          <PrivateRoute path={"/account/cash"} exact component={AccountCash} accessRole={'user'} />
          <PrivateRoute path={"/agency"} exact component={AgencyMenu} accessRole={'user'} />
          <PrivateRoute path={"/agency/list"} exact component={AgenciesList} accessRole={'user'} />
          <PrivateRoute path={"/agency/list/:id"} component={Agency} accessRole={'user'} />
          <PrivateRoute path={"/prevalue"} exact component={PrevalueList} accessRole={'user'} />
          <PrivateRoute path={"/prevalue/electro"} exact component={PrevalueElectro} accessRole={'user'} />
          <PrivateRoute path={"/prevalue/jewel"} exact component={PrevalueJewelry} accessRole={'user'} />
          <PrivateRoute path={"/promotions"} exact component={Promotion} accessRole={'user'} />
          <PrivateRoute path={"/faq"} exact component={Faq} accessRole={'user'} />

          <Route path={"/prevaluos"} exact component={NoLPrevalueList} />
          <Route path={"/prevaluos/electro"} exact component={NoLPrevalueElectro} />
          <Route path={"/prevaluos/jewel"} exact component={NoLPrevalueJewelry} />


          <Route path={"/admin/login"} exact component={AdminLogin} accessRole={'admin'} />
          <PrivateRoute path={"/admin/"} exact component={AdminDashboard} accessRole={'admin'} />
          <PrivateRoute path={"/admin/profile"} exact component={AdminProfile} accessRole={'admin'} />
          <PrivateRoute path={"/admin/user/list"} exact component={AdminUserList} accessRole={'admin'} />
          <PrivateRoute path={"/admin/user/create"} exact component={AdminCreateUser} accessRole={'admin'} />
          <PrivateRoute path={"/admin/user/list/:id"} exact component={AdminUpdateUser} accessRole={'admin'} />
          <PrivateRoute path={"/admin/admin/list"} exact component={AdminAdminList} accessRole={'admin'} />
          <PrivateRoute path={"/admin/admin/create"} exact component={AdminCreateAdmin} accessRole={'admin'} />
          <PrivateRoute path={"/admin/admin/edit/:id"} exact component={AdminUpdateAdmin} accessRole={'admin'} />
          <PrivateRoute path={"/admin/agency/list"} exact component={AdminAgencyList} accessRole={'admin'} />
          <PrivateRoute path={"/admin/agency/list/:id"} exact component={AdminAgency} accessRole={'admin'} />
          <PrivateRoute path={"/admin/promotion/create"} exact component={AdminCreatePromotion} accessRole={'admin'} />
          <PrivateRoute path={"/admin/promotion/edit/:id"} exact component={AdminUpdatePromotion} accessRole={'admin'} />
          <PrivateRoute path={"/admin/promotion/list"} exact component={AdminPromotionList} accessRole={'admin'} />
          <PrivateRoute path={"/admin/banner/create"} exact component={AdminCreateBanner} accessRole={'admin'} />
          <PrivateRoute path={"/admin/banner/edit/:id"} exact component={AdminUpdateBanner} accessRole={'admin'} />
          <PrivateRoute path={"/admin/banner/list"} exact component={AdminBannerList} accessRole={'admin'} />
          <PrivateRoute path={"/admin/prevalue/electro"} exact component={AdminprevaluesElectroList} accessRole={'admin'} />
          <PrivateRoute path={"/admin/prevalue/electro/:id"} exact component={AdminPrevalueElectro} accessRole={'admin'} />
          <PrivateRoute path={"/admin/prevalue/jewelry"} exact component={AdminPrevaluesJewelryList} accessRole={'admin'} />
          <PrivateRoute path={"/admin/prevalue/jewelry/:id"} exact component={AdminPrevalueJewelry} accessRole={'admin'} />
          <PrivateRoute path={"/admin/configuration"} exact component={AdminConfiguration} accessRole={'admin'} />

          <Route path={"*"} component={Error404} />
        </Switch>
      </ErrorBoundary>
    </Router>
  }

}

const mapStateToProps = (state) => ({
  loader: getLoader(state)
})

const mapDispatchToProps = { fetchConfiguration, fetchAgencies, fetchEntity }

export default connect(mapStateToProps, mapDispatchToProps)(App)