import React from 'react'
import { Card, Form, Button, Input} from 'antd'
import styles from '../styles'

class NoLoginForm extends React.Component {


    render() {
        const { setInitialForm } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            headStyle={styles.cover}
        >
            <Form
                name={"noLoginForm"}
                onFinish={setInitialForm}
                layout={"vertical"}
            >
                <Form.Item label={"Celular"} name={"phone"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input type={"tel"} pattern={"[0-9]{8}"} placeholder={"########"} />
                </Form.Item>
                <Form.Item label={"Nombre"} name={"name"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Button type={"primary"} htmlType="submit">Continuar</Button>
            </Form>
        </Card>
    }
}

export default NoLoginForm

