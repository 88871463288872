import { message } from 'antd'
import axios from 'axios'
import {
    FETCH_BANNERS,
    APIpath
} from '../actions'

const setBanners = (banners) => ({
    type: FETCH_BANNERS,
    banners
})

export const fetchBanners = () => (dispatch) => {
    axios.get(`${APIpath}/banner/`)
        .then(function (response) {
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const fetchActiveBanners = () => (dispatch) => {
    axios.get(`${APIpath}/banner/active-banner`)
        .then(function (response) {
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const createBanner = (values) => (dispatch) => {
    axios.post(`${APIpath}/banner/store`, values)
        .then(function (response) {
            message.success("Banner guardado con exito")
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const updateBanner = (values) => (dispatch) => {
    axios.post(`${APIpath}/banner/update`, values)
        .then(function (response) {
            message.success("banner actualizado con exito")
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const updateStatusBanner = (id) => dispatch => {
    axios.post(`${APIpath}/banner/status`, { id })
        .then(function (response) {
            message.success("Banner actualizado con exito")
            dispatch(setBanners(response.data))
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}