import React from 'react'
import { connect } from 'react-redux'
import { createBanner } from '../../actions'
import { Form, Card, Button, Input, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import Layout from '../../layout'
import styles from '../../styles'

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e
    }
    return e && e.fileList
}

class CreateBanner extends React.Component {

    state = {
        fileList: []
    }

    onSubmit = (values) => {
        const { createBanner } = this.props
        const { fileList } = this.state
        const formData = new FormData()
        fileList.forEach(file => {
            formData.append('file', file)
        })
        formData.append('description', values.description)
        formData.append('name', values.name)
        createBanner(formData)
    }

    render() {
        return <Layout type={"backpage"}>
            <Card
                style={styles.generalCard}
                title={"Creación de promoción"}
            >
                <Form
                    name={"create_Banner"}
                    onFinish={this.onSubmit}
                    layout={"vertical"}
                >
                    <Form.Item label={"Titulo"} name={"name"} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                        <Input placeholder={"Titulo"} />
                    </Form.Item>
                    <Form.Item label={"Descripción"} name={"description"} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                        <Input.TextArea placeholder={"Descripción"} />
                    </Form.Item>

                    <Form.Item name="image" label="Banner" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                        <Upload
                            name="image"
                            onRemove={file => {
                                this.setState(state => {
                                    const index = state.fileList.indexOf(file)
                                    const newFileList = state.fileList.slice()
                                    newFileList.splice(index, 1)
                                    return {
                                        fileList: newFileList,
                                    }
                                })
                            }}

                            beforeUpload={file => {
                                this.setState(state => ({
                                    fileList: [...state.fileList, file],
                                }))
                                return false
                            }}
                            listType="picture"
                            accept="image/png, image/jpeg"
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Subir banner</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit">Guardar</Button>
                    </Form.Item>
                </Form>
            </Card>
        </Layout>
    }
}

const mapStateToProps = undefined

const mapDispatchToProps = ({ createBanner })

export default connect(mapStateToProps, mapDispatchToProps)(CreateBanner)