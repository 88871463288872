import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Card, Button } from 'antd'
import { getBanners } from '../../reducers'
import { APIpath, fetchBanners } from '../../actions'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tableBanners = (banners) => {
    return banners.map((banner) => {
        return {
            key: banner.id,
            name: banner.name,
            description: banner.description,
            status: banner.status,
            image: banner.image,
            filetype: banner.filetype
        }
    })
}

class BannerList extends React.Component {

    componentDidMount() {
        this.props.fetchBanners()
    }

    render() {
        const { banners } = this.props
        return (
            <Layout type={"backpage"} >
                <Card style={styles.generalCard}>
                    <Table dataSource={tableBanners(banners)} >
                        <Column title={"Nombre"} render={(record) => record.name} />
                        <Column title={"Estado"} render={(record) => record.status ? 'Activo' : 'Inactivo'} />
                        <Column title={"Banner"} render={(record) =>
                            <img src={`${APIpath}/Banner/image/${record.key}`} style={{width: '60px', height: '60px'}} alt={record.key} />
                        } />
                        <Column title={"Opciones"} render={(record) =>
                            <Link to={`/admin/Banner/edit/${record.key}`}>
                                <Button type={"primary"}>Editar</Button>
                            </Link>
                        } />
                    </Table>
                </Card>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    banners: getBanners(state)
})

const mapDispatchToProps = { fetchBanners }

export default connect(mapStateToProps, mapDispatchToProps)(BannerList)