import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Col, Typography } from 'antd'
import Layout from '../../layout'
import bannerdirectorio from '../../multimedia/bannerdirectorio.png'
import icon8 from '../../multimedia/icon8.svg'
import icon9 from '../../multimedia/icon9.svg'
import styles from '../../styles'

class AgencyMenu extends React.Component {


    render() {
        return <Layout type={"frontpage"}>
            <Card
                cover={<img src={bannerdirectorio} alt={"Fondo"} />}
                bordered={false}
                style={styles.generalCard}
            >
                <Card className={"text-center"}>
                    <Row>
                        <Col xs={24} md={12}>
                            <Link to='/agency/list'>
                                <img src={icon9} alt={"Directorio de AGENCIAS"} style={styles.iconMenu} />
                                <Typography.Title level={5} > Directorio de AGENCIAS</Typography.Title>
                            </Link>
                        </Col>
                        <Col xs={24} md={12}>
                            <Link to='/faq'>
                                <img src={icon8} alt={"preguntas frecuentes"} style={styles.iconMenu} />
                                <Typography.Title level={5} > Preguntas frecuentes</Typography.Title>
                            </Link>
                        </Col>
                    </Row>
                </Card>
            </Card>
        </Layout>
    }
}

export default AgencyMenu