import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Card, Button } from 'antd'
import { getAgencies } from '../../reducers'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tableAgencies = (agencies) => {
    return agencies.map((agency) => {
        return {
            key: agency.id,
            name: agency.name,
            phone: agency.cNroWhatsApp
        }
    })
}

class AgencyList extends React.Component {

    render() {

        const { agencies } = this.props
        return (
            <Layout type={"backpage"}>
                <Card style={styles.generalCard} >
                    <Table dataSource={tableAgencies(agencies)} >
                        <Column title={"Nombre"} render={(record) => record.name} />
                        <Column title={"Teléfono"} render={(record) => record.phone} />
                        <Column title={"Detalles"} render={(record) =>
                            <Link to={`/admin/agency/list/${record.key}`}>
                                <Button type={"primary"}>Detalles</Button>
                            </Link>
                        } />
                    </Table>
                </Card>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    agencies: getAgencies(state)
})

const mapDispatchToProps = undefined

export default connect(mapStateToProps, mapDispatchToProps)(AgencyList)