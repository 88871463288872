import React from 'react'
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, Typography
} from 'antd'
import Layout from '../../layout'
import bannerempeno from '../../multimedia/bannerempeno.png'
import icon13 from '../../multimedia/icon13.svg'
import icon11 from '../../multimedia/icon11.svg'
import styles from '../../styles'

class PrevalueList extends React.Component {

    render() {
        return <Layout type={"NoLogin"}>
            <Card
                cover={<img src={bannerempeno} alt={"Fondo"} />}
                bordered={false}
                className={'text-center'}
            >
                <Row>
                    <Col span={24}>
                        <Link to={"/prevaluos/electro"}>
                            <img src={icon13} alt={"ARTICULOS y MÁS"} style={styles.iconMenu} />
                            <Typography.Title level={5} >ARTICULOS y MÁS</Typography.Title>
                        </Link>
                    </Col>
                    <Col span={24}>
                        <Link to='/prevaluos/jewel'>
                            <img src={icon11} alt={"JOYAS DE ORO Y PLATA"} style={styles.iconMenu} />
                            <Typography.Title level={5} >JOYAS DE ORO Y PLATA</Typography.Title>
                        </Link>
                    </Col>
                </Row>
            </Card>
        </Layout>
    }
}

export default PrevalueList