import React from 'react'
import { Card, Typography } from 'antd'
import Layout from '../../layout'
import styles from '../../styles'

class Faq extends React.Component {

    render() {
        return <Layout type={"frontpage"}>
            <Card
                title={<Typography.Title level={2} className={"text-center"} style={styles.whiteText}>Preguntas frecuentes</Typography.Title>}
                headStyle={styles.cover}
                style={styles.generalCard}
                bordered={false}
            >
                <Typography.Paragraph>
                    <b>1. ¿Cuánto tiempo dura un contrato?</b><br />
                    <b>R/</b>Puedo tener mi contrato por tiempo indefinido si pago los intereses en tiempo. <br /><br />
                    <b>2.¿Cuáles son las formas de pago? </b> <br />
                    <b>R/</b> Renovación: Pago mis Intereses por el tiempo que deseo. <br />
                    Amortización: Pago mis Intereses y también abono a la capital desde C$1. <br />
                    Cancelación: Cuando cancelo la capital y los intereses.
                    <br /><br />
                    <b>3. ¿Es posible pagar en otras agencias? </b> <br />
                    <b>R/</b> Puedo pagar facilmente en cualquiera de las 23 agencias de Credifast. <br /><br />
                    <b>4. ¿Es posible enviar a pagar a otra persona? </b> <br />
                    <b>R/</b> Por supuesto! La persona autorizada debe presentarse a la agencia y llevar información del contrato y/o número de cédula de la persona del contrato. <br /><br />
                    <b>5. ¿Otra persona puede retirar las prendas? </b> <br />
                    <b>R/</b> Sí, siempre y cuando esta persona haya quedado como autorizada en el contrato mismo y/o presente poder especial o general. <br /><br />
                    <b>6. ¿El producto/prenda es entregado el mismo día que se cancela el contrato? </b> <br />
                    <b>R/</b> El artículo y/o prenda pequeña se entrega el mismo día, sin embargo dado que para Credifast lo más importante es la seguridad del cliente, las prendas grandes no se entregan el mismo día ya que se encuentran resguardadas en una bóveda de seguridad.  <br /><br />
                    <b>7. ¿Cuál es el horario de atención? </b><br />
                    <b>R/</b> Lunes a Viernes: 08:00 am a 05:30 pm / Sábado: 08:00 am a 01:30 pm <br />
                    <b> Multicentro Las Americas : </b> Lunes a Sábado: 09:30 am a 07:00 pm / Domingo: 11:00 am a 07:00 pm
                </Typography.Paragraph>
            </Card>
        </Layout>
    }
}

export default Faq