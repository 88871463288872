import React from 'react'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import {
    UserOutlined, NotificationOutlined,
    DashboardOutlined, ShopOutlined,
    TeamOutlined, WalletOutlined,
    SettingOutlined, PictureOutlined
} from '@ant-design/icons'

const { Sider } = Layout
const { SubMenu } = Menu

const Sidebar = ({
    trigger,
    collapsible = false,
    collapsed = false,
    admin
}) => {
    return (
        < Sider width={200} trigger={trigger} collapsible={collapsible} collapsed={collapsed} >
            <Menu
                mode="inline"
                style={{ height: '100%' }}
            >
                <Menu.Item key="1" icon={<DashboardOutlined />}>
                    <Link to={"/admin/"}>Dashboard</Link>
                </Menu.Item>
                <Menu.Item key="2" icon={<ShopOutlined />}>
                    <Link to={"/admin/agency/list"}> Agencias </Link>
                </Menu.Item>

                <SubMenu key="sub1" icon={<UserOutlined />} title="Usuarios">
                    <Menu.Item key="3">
                        <Link to={"/admin/user/create"}>Habilitar usuario </Link>
                    </Menu.Item>
                    <Menu.Item key="4">
                        <Link to={"/admin/user/list"}> Listado </Link>
                    </Menu.Item>
                </SubMenu>
                {admin.role_id !== 2 &&
                    <React.Fragment>
                        <SubMenu key="sub3" icon={<WalletOutlined />} title="Pre-valúos">
                            <Menu.Item key="8">
                                <Link to={"/admin/prevalue/electro/"}>Articulos y más</Link>
                            </Menu.Item>
                            <Menu.Item key="9">
                                <Link to={"/admin/prevalue/jewelry/"}>Joyas de oro y plata</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub2" icon={<TeamOutlined />} title="Administrador">
                            <Menu.Item key="5">
                                <Link to={"/admin/admin/create"}>Nuevo</Link>
                            </Menu.Item>
                            <Menu.Item key="6">
                                <Link to={"/admin/admin/list"}>Listado</Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4" icon={<NotificationOutlined />} title="Promociones">
                            <Menu.Item key="12">
                                <Link to={"/admin/promotion/create"}>Crear </Link>
                            </Menu.Item>
                            <Menu.Item key="13">
                                <Link to={"/admin/promotion/list"}>Listado </Link>
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub5" icon={<PictureOutlined />} title="Banner">
                            <Menu.Item key="14">
                                <Link to={"/admin/banner/create"}>Crear </Link>
                            </Menu.Item>
                            <Menu.Item key="15">
                                <Link to={"/admin/banner/list"}>Listado </Link>
                            </Menu.Item>
                        </SubMenu>
                        <Menu.Item key="16" icon={<SettingOutlined />} >
                            <Link to={"/admin/configuration"}>Configuración</Link>
                        </Menu.Item>
                    </React.Fragment>
                }
            </Menu>
        </Sider >
    )
}

export default Sidebar