import React from 'react'
import { connect } from 'react-redux'
import { getUser, getAuth } from '../../reducers'
import { saveJewelryPrevalue } from '../../actions'
import Layout from '../../layout'
import Jewelry from '../../containers/Jewelry'
import AgencySelection from '../../containers/AgencySelection'
import EmailSend from '../../containers/EmailSend'
import ConfirmationBlock from '../../components/ConfirmationBlock'
import { message } from 'antd'

class PrevalueJewelry extends React.Component {

    state = {
        page: 1,
        metal: '',
        prenda: '',
        kilataje: '925',
        peso: 0,
        image1: [],
        image2: [],
        image3: [],
        image4: [],
        delivery: 0,
        agency: 0,
        address: '',
        addressRef: '',
        departamento: '',
        municipio: '',
        phone: '',
        email: '',
        contactFrom: 0
    }

    setErrorCount = () => {

        if(this.state.errorCount > 0 ){
            message.info('Tienes problemas porfavor comunicate a Whatsapp para ayudarte');
        }else{
            this.setState({errorCount: 1 })
        }
        
    }

    setJewelry = (entities) => {
        this.setState(entities, () => { this.setState({ page: 3 }) })
    }

    setUpdateState = (object) => {
        this.setState(object)
    }


    setAgency = (value) => {
        this.setState({ agency: value }, () => {
            this.setState({ page: 5 })
        })
    }

    setEmail = (values) => {
        this.setState(values, () => {
            this.saveForm()
        })
    }

    saveForm = () => {
        const { auth, saveJewelryPrevalue } = this.props

        const formData = new FormData()
        this.state.image1.forEach(file => {
            formData.append('image1', file.originFileObj)
        })
        this.state.image2.forEach(file => {
            formData.append('image2', file.originFileObj)
        })
        this.state.image3.forEach(file => {
            formData.append('image3', file.originFileObj)
        })
        if (typeof this.state.image4 !== 'undefined') {
            this.state.image4.forEach(file => {
                formData.append('image4', file.originFileObj)
            })
        }
        formData.append('metal', this.state.metal)
        formData.append('prenda', this.state.prenda)
        formData.append('kilataje', this.state.kilataje)
        formData.append('peso', this.state.peso)
        formData.append('delivery', this.state.delivery)
        formData.append('agency', this.state.agency)
        formData.append('address', this.state.address)
        formData.append('addressRef', this.state.addressRef)
        formData.append('departamento', this.state.departamento)
        formData.append('municipio', this.state.municipio)
        formData.append('contactFrom', this.state.contactFrom)
        formData.append('nCodPers', auth.nCodPers)
        formData.append('name', auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat)
        formData.append('phone', this.state.phone === '' ? auth.cMovil : this.state.phone)
        formData.append('email', this.state.email === '' || this.state.email === undefined ? auth.cMail : this.state.email)
        saveJewelryPrevalue(formData)
        this.setState({ page: 6 })
    }

    cancelDelivery = () => this.setState({ page: 1, delivery: 0, kilataje: 925 })
    cancelAgency = () => this.setState({ page: 1, agency: 0 })
    cancelEmail = () => this.setState({ page: 3 })


    render() {
        const { page } = this.state
        switch (page) {
            case 3:
                return <Layout type={"frontpage"} >
                    <AgencySelection cancelAgency={this.cancelAgency} setAgency={this.setAgency} setError={this.setErrorCount} />
                </Layout>
            case 5:
                return <Layout type={"frontpage"} >
                    <EmailSend cancelEmail={this.cancelEmail} setEmail={this.setEmail} setError={this.setErrorCount} />
                </Layout>
            case 6:
                return <Layout type={"frontpage"} >
                    <ConfirmationBlock
                        message={` Estimado cliente: su solicitud de prevalúo ha sido enviado
                        exitosamente, un ejecutivo se comunicará contigo para
                        brindarle el monto de préstamo aproximado.
                        Credifast donde pagas menos y te damos más`}
                    />

                </Layout>
            default:
                return <Layout type={"frontpage"} >
                    <Jewelry setJewelry={this.setJewelry} setUpdateState={this.setUpdateState} setError={this.setErrorCount}/>
                </Layout>
        }
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state))
})

const mapDispatchToProps = { saveJewelryPrevalue }

export default connect(mapStateToProps, mapDispatchToProps)(PrevalueJewelry)