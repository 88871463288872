import background from "../multimedia/backgroundGeneric.jpg"

const primaryColor = '#0060a9'
const secondaryColor = '#ecc400'

const styles = {
    layout: {
        overflow: "auto",
        backgroundColor: '#FFFFFF'
    },
    layoutAdmin: {
        overflow: "auto",
        backgroundColor: '#FFFFFF',
        height: "100vh",
    },
    appbar: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'space-between'
    },
    yellowLine: {
        background: secondaryColor,
        minHeight: '5px',
        margin: 0
    },
    logo: {
        maxHeight: '60px'
    },
    appWithMargin: {
        marginRight: "auto",
        marginLeft: "auto",
        minHeight: '280px'
    },
    app: {
        minHeight: '280px'
    },
    layoutAuthpage: {
        height: "100vh",
        overflow: "auto",
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    cardAuthpage: {
        backgroundColor: 'rgba(255,255,255, 0.8)',
        border: primaryColor
    },
    cardHeaderInformation: {
        justifyContent: 'center',
        display: 'flex',
        marginTop: '5px',
        marginBottom: '5px'
    },
    footer: {
        textAlign: 'center',
        backgroundColor: '#FFF',
        padding: 0
    },
    chatButton: {
        maxHeight: '70px',
        width: '100%'
    },
    generalCard: {
        maxWidth: '800px',
        width:'100%',
        margin: 'auto',
        marginBottom: '10px'
    },
    cardNoPadding: {
        padding: 0
    },
    cardNoPaddingTop: {
        paddingTop: 0
    },
    iconMenu: {
        minHeight: '100px',
        minWidth: '100px',
        maxHeight: '124px',
        maxWidth: '124px',
        height: '100%',
        width: '100%'
    },
    cover: {
        background: primaryColor,
        minHeight: '90px'
    },
    whiteText: {
        color: '#fff'
    },
    mapContainer: {
        width: '100%',
        height: '400px'
    },
    blockInformationPrimary: {
        background: primaryColor,
        color: '#fff',
        padding: 5,
        width: '90%',
        borderRadius: '10px',
        textAlign: 'center',
        marginTop: '5px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    blockInformationPrimary2: {
        background: primaryColor,
        color: '#fff',
        padding: 5,
        width: '90%',
        borderRadius: '10px',
        textAlign: 'justify',
        marginTop: '5px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    greenCover: {
        background: '#52c41a',
        color: '#FFF',
        borderRadius: '10px',
        padding: '5px'
    },
    yellowCover: {
        background: secondaryColor,
        color: '#000',
        borderRadius: '10px',
        padding: '5px'
    },
    ThemeCover: {
        background: primaryColor,
        color: '#FFF',
        borderRadius: '10px',
        padding: '10px',
        fontWeight: 'bolder',
        fontSize: '18px'
    },
    titleInsideCover: {
        position: 'absolute',
        color: '#FFF',
        textAlign: 'center'
    },
    coverWithText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexColumnCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    greenBtext: {
        background: '#52c41a',
        color: '#FFF',
        borderRadius: '5px',
        padding: '3px',
        marginTop: '5px',
        textAlign: 'center'
    },
    backgroundOpacity:{
        backgroundColor: 'rgba(255,255,255,0.9)',
        textAlign: 'center'
    },
    textGreen: {
        color: '#52c41a',
        fontWeight: "bolder"
    },
    textBlack: {
        color: '#000',
        fontWeight: "bolder"
    },
    textRed: {
        color: 'red',
        fontWeight: "bolder"
    }

}

export default styles