import React from 'react'
import { connect } from 'react-redux'
import { getUser, getAuth, getConfig, getGoodies } from '../../reducers'
import { fetchGoodies } from '../../actions'
import { Card, Typography, Table } from 'antd'
import CardHeaderInformation from '../../components/CardHeaderInformation'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tableGoodies = (goodies) => {
    return goodies.map(goodie => {
        return {
            key: goodie.nCodPrgRecGana,
            dFecCobro: goodie.dFecCobro,
            namePers: goodie.namePers,
            nPremio: goodie.nPremio
        }
    })
}

class Goodies extends React.Component {

    componentDidMount() {
        this.props.fetchGoodies(this.props.auth.nCodPers)
    }

    getTotalMoneyAvailable = () => {
        return this.props.goodies.reduce((total, goodie) => {
            let amount = goodie.nPremio
            return total + amount
        }, 0)
    }

    render() {
        const { auth, goodies } = this.props
        return <Layout type={"frontpage"}>
            <Card bordered={false}
                title={
                    <React.Fragment>
                        <Typography.Title level={2} className={"text-center"} style={styles.whiteText}>PREMIOS <br /> POR RECOMENDADOS</Typography.Title>
                    </React.Fragment>
                }
                headStyle={styles.cover}
                bodyStyle={styles.cardNoPadding}
                style={styles.generalCard}
            >
                <CardHeaderInformation
                    name={auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat}
                    dni={auth.cDNI}
                />
                <Card bordered={false} className={"text-center"}>
                    <Typography.Title level={3} style={styles.yellowCover}  >TOTAL PREMIOS <br />C{parseFloat(this.getTotalMoneyAvailable()).toFixed(2)} </Typography.Title>
                </Card>
                <Table dataSource={tableGoodies(goodies)} size={"small"} pagination={false} >
                    <Column title={"Fecha Vec."} render={(record) => record.dFecCobro} />
                    <Column ellipsis={true} title={"Recomendado"} render={(record) => record.namePers} />
                    <Column title={"Valor premio"} render={(record) => `$C${parseFloat(record.nPremio).toFixed(2)}`} />
                </Table>
                <Typography.Paragraph style={styles.blockInformationPrimary2}>
                    Estimado
                    cliente: le informamos que la vigencia del premio es de 3 meses y puede ir a
                    canjearlo a cualquier agencia Credifast.
                </Typography.Paragraph>
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state)),
    config: getConfig(state),
    goodies: getGoodies(state)
})

const mapDispatchToProps = { fetchGoodies }

export default connect(mapStateToProps, mapDispatchToProps)(Goodies)