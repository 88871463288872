import { combineReducers } from 'redux'
import admins, * as selectorsFromAdmin from './admins'
import users, * as selectorsFromUser from './users'
import contracts, * as selectorsFromContract from './contracts'
import invoices, * as selectorsFromInvoice from './invoices'
import auth, * as selectorsFromAuth from './auth'
import agencies, * as selectorsFromAgency from './agencies'
import promotions, * as selectorsFromPromotion from './promotions'
import banners, * as selectorsFromBanner from './banner'
import config, * as selectorsFromConfig from './config'
import voucher, * as selectorsFromVoucher from './voucher'
import goodies, * as selectorsFromGoodies from './goodies'
import entities, * as selectorsFromEntity from './entities'
import prevalues, * as selectorsFromPrevalues from './prevalues'
import ui, * as selectorsFromUi from './ui'

const rootReducer = combineReducers({
    auth,
    admins,
    users,
    contracts,
    invoices,
    agencies,
    promotions,
    voucher,
    goodies,
    prevalues,
    entities,
    config,
    banners,
    ui
})

export const getAdmins = (state) => selectorsFromAdmin.getAdmins(state.admins)
export const getAdmin = (state, id) => selectorsFromAdmin.getAdmin(state.admins, id)

export const getUsers = (state) => selectorsFromUser.getUsers(state.users)
export const getUser = (state, id) => selectorsFromUser.getUser(state.users, id)

export const getContracts = (state) => selectorsFromContract.getContracts(state.contracts)
export const getContract = (state, id) => selectorsFromContract.getContract(state.contracts, id)

export const getInvoices = (state) => selectorsFromInvoice.getInvoices(state.invoices)
export const getInvoice = (state, id) => selectorsFromInvoice.getInvoice(state.invoices, id)

export const getVoucher = (state) => selectorsFromVoucher.getVoucher(state.voucher)
export const getGoodies = (state) => selectorsFromGoodies.getGoodies(state.goodies)

export const getPrevalues = (state) => selectorsFromPrevalues.getPrevalues(state.prevalues)
export const getPrevalue = (state, id) => selectorsFromPrevalues.getPrevalue(state.prevalues, id)

export const getAuth = (state) => selectorsFromAuth.getAuth(state.auth)
export const getRole = (state) => selectorsFromAuth.getRole(state.auth)

export const getAgencies = (state) => selectorsFromAgency.getAgencies(state.agencies)
export const getAgency = (state, id) => selectorsFromAgency.getAgency(state.agencies, id)

export const getPromotions = (state) => selectorsFromPromotion.getPromotions(state.promotions)
export const getPromotion = (state, id) => selectorsFromPromotion.getPromotion(state.promotions, id)

export const getBanners = (state) => selectorsFromBanner.getBanners(state.banners)
export const getBanner = (state, id) => selectorsFromBanner.getBanner(state.banners, id)
export const getActiveBanner = (state) => selectorsFromBanner.getActiveBanner(state.banners)

export const getConfig = (state) => selectorsFromConfig.getConfig(state.config)
export const getEntity = (state, name) => selectorsFromEntity.getEntity(state.entities, name)

export const getLoader = (state) => selectorsFromUi.getLoader(state.ui)
export const getRedirect = (state) => selectorsFromUi.getRedirect(state.ui)

export default rootReducer