const banner = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_BANNERS': return action.banners
        case 'FETCH_BANNER':
            state.push(action.banner)
            return state
        default: return state
    }
}

export const getBanners = (state) => state
export const getBanner = (state, id) => state.reduce((p, banner) => {
    if ( Number(banner.id) === Number(id)) return banner
    return p
}, {})

export const getActiveBanner = (state) => state.reduce((p ,banner ) => { 
    if(banner !== null)  return banner
    return p
}, null)

export default banner