import React from 'react'
import { connect } from 'react-redux'
import { getAgency } from '../../reducers'
import styles from '../../styles'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import {
    Card, Button, Skeleton, List, Descriptions, Tooltip
} from 'antd'
import {
    WhatsAppOutlined
} from '@ant-design/icons'
import Layout from '../../layout'
import { isEmpty } from '../../utils'
import { APIpath } from '../../actions'


const Agency = (props) => {

    const { agency } = props
    if (isEmpty(agency)) return <Skeleton />
    return (
        <Layout type={"frontpage"}>
            <Card style={styles.generalCard}
                size={'small'}
                cover={<img src={`${APIpath}/agency/image/${agency.id}`} alt={agency.name} />}
                title={agency.name}
                extra={<Tooltip title="Escribenos">
                <Button 
                    type="primary" 
                    shape="circle" 
                    icon={<WhatsAppOutlined />} 
                    href={`https://wa.me/+505${agency.cNroWhatsApp}`} 
                    target={"_blank"} 
                    rel="noreferrer"
                />
            </Tooltip>}
            >
                <br />
                <LoadScript googleMapsApiKey="AIzaSyCggvraT4pGMXL0J4wLlfp_b5yoViJ95EY" >
                    <GoogleMap
                        mapContainerStyle={styles.mapContainer}
                        center={{
                            lat: agency.nCoordenadaX,
                            lng: agency.nCoordenadaY
                        }}
                        zoom={16}
                    >
                        <Marker position={{
                            lat: agency.nCoordenadaX,
                            lng: agency.nCoordenadaY
                        }} ></Marker>
                    </GoogleMap>
                </LoadScript>
                <br />
                <List
                    bordered
                    size="large"
                    dataSource={[
                        `Dirección: ${agency.address}`,
                        <Descriptions title={"Horario"} bordered>
                            <Descriptions.Item label={"Lunes"}>{agency.cHorarioLun}</Descriptions.Item>
                            <Descriptions.Item label={"Martes"}>{agency.cHorarioMar}</Descriptions.Item>
                            <Descriptions.Item label={"Miércoles"}>{agency.cHorarioMie}</Descriptions.Item>
                            <Descriptions.Item label={"Jueves"}>{agency.cHorarioJue}</Descriptions.Item>
                            <Descriptions.Item label={"Viernes"}>{agency.cHorarioVie}</Descriptions.Item>
                            <Descriptions.Item label={"Sábado"}>{agency.cHorarioSab}</Descriptions.Item>
                            <Descriptions.Item label={"Domingo"}>{agency.cHorarioDom === null ? 'Cerrado' : agency.cHorarioDom}</Descriptions.Item>
                        </Descriptions>
                    ]}
                    renderItem={item => <List.Item>{item}</List.Item>}
                />
                <br />
            </Card>
        </Layout>
    )
}

const mapStateToProps = (state, ownProps) => ({
    agency: getAgency(state, ownProps.match.params.id)
})

const mapDispatchToProps = undefined

export default connect(mapStateToProps, mapDispatchToProps)(Agency)